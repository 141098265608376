* {
    FONT-FAMILY: "Century Gothic", "Trebuchet MS", "Avant Garde", "Georgia", "Verdana", "Helvetica", "Tahoma"
}



.active-link {
    /* Define your active link style here */
    font-weight: bold;
    /* etc... */
}

.nav-emphasized-background {
    background-color: #C6D2B2;
    color: black;
    border-radius: 15px;
}

.nav-emphasized-link {
    color: #204176;
}

.narrow-input {
    width: 50% !important;
}

.wide-input {
    width: 100% !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.form-control::placeholder {
    font-style: italic;
}

.select-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.select-wrapper .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
}

.card-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    /* margin-bottom: 0.5rem; */
    /*
flex-start: Aligns items to the start of the container. This is the default value.
flex-end: Aligns items to the end of the container.
center: Centers items within the container.
space-between: Distributes items evenly, with the first item aligned to the start of the container and the last item aligned to the end of the container.
space-around: Distributes items evenly with equal space around them.
space-evenly: Distributes items evenly with equal space between them.
*/
}

.form-input-text {
    font-size: 0.8rem !important;
    font-weight: normal !important;
}

.form-label {
    font-size: 0.8rem !important;
    font-weight: bold !important;
}

.form-textarea {
    font-size: 0.8rem !important;
    font-weight: normal !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 100%;
}

.logo {
    /* float: left; */
    margin: 0;
    padding: 0;
    font-family: "Century Gothic", "Trebuchet MS", "Avant Garde", "Georgia", "Verdana", "Helvetica", "Tahoma";
}

.logoUme {
    font-size: 36px;
}

.logoHem {
    font-weight: bold;
    font-size: 36px;
}

.logoFastigheter {
    font-size: 14px;
}

.address {
    font-size: 14px;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */